@use "../../settings/_variable" as v;
@use "../../settings/_mixin" as r;
@use "../../settings/_path" as p;

.p-top {
    &1 {
        background-color: #f5f5f5;
        padding-top: 50px;
        padding-bottom: 50px;

        @include r.sp {
            padding-top: 30px;
        }

        .c-title1 {
            margin-bottom: 40px;

            @include r.sp {
                margin-bottom: 20px;
            }
        }

        &__yt {
            max-width: 840px;
            width: 100%;
            height: 472px;
            aspect-ratio: 16 / 9;
            margin: 0 auto;
            @include r.sp {
                height: 52vw;
            }

            @include r.max(480px) {
                height: 47.8vw;
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &2 {
        padding-top: 52px;
        padding-bottom: 40px;
        background-color: #fff;

        @include r.sp {
            padding-top: 30px;
            padding-bottom: 47px;

            .c-list1 {
                &__title {
                    margin-top: 18px;
                }
            }
        }

        .c-title1 {
            margin-bottom: 38px;
            font-size: 2.8rem;

            @include r.sp {
                font-size: 2.2rem;
                margin-bottom: 20px;
            }

            span {
                padding-bottom: 9px;

                @include r.sp {
                    padding-bottom: 0;
                }
            }
        }

        &__inner {
            padding: 0 124px;

            @include r.sp {
                padding: 0;
            }
        }

        .c-list1 {
            margin: 0 -5px;

            @include r.sp {
                margin: 0;
            }
        }
    }

    &3 {
        background-color: #f5f5f5;
        padding-top: 40px;
        padding-bottom: 40px;

        @include r.sp {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .c-title1 {
            font-size: 2.8rem;
            margin-bottom: 34px;

            @include r.sp {
                font-size: 2rem;
                line-height: 1;
                margin-bottom: 21px;
            }

            span {
                padding-bottom: 4px;

                @include r.sp {
                    padding-bottom: 0;
                }
            }
        }

        .c-list1 {
            max-width: 900px;
            margin: 0 auto;
            @include r.sp {
                max-width: 100%;
            }

            &__link {
                padding-left: 12px;

                @include r.sp {
                    padding-left: 0;
                }
            }

            &__label {
                margin-right: 32px;

                @include r.sp {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }

        &__info {
            box-shadow: 0 0 10px rgba($color: v.$text-color, $alpha: 0.16);
            background-color: #fff;
            padding: 55px 20px 60px;

            @include r.sp {
                padding: 32px 19px 30px;
                margin-left: -10px;
                margin-right: -10px;
            }

            .c-btn2 {
                margin-top: 50px;

                @include r.sp {
                    margin-top: 30px;
                }
            }
        }
    }
}
